import React, {useEffect, useState} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductFeatures from "../components/productfeatures"
import ContactForm from "../components/contactform"
import Payment from "../components/payment"
import MobilePayment from "../components/mobilepayment"
import PropTypes from "prop-types"
import PhotoVideoCopy from "../components/photoVideoCopy"
import PricingAlert from "../components/pricingAlert"
import useIsMobile from "../hooks/useIsMobile"

let propTypes = {
  data: PropTypes.string.isRequired,
}

export default function PaymentPage(props){
  const isMobile = useIsMobile('1024px');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const alertTimer = setTimeout(() => {
      setShowAlert(true);
    }, 5000) 
  
    return () => {
      clearTimeout(alertTimer);
    }
  }, [])

  const scrollToForm = () => {
    document.getElementById("pricealert")
      ? (document.getElementById("pricealert").style.display = "none")
      : null;

    window.addEventListener("hashchange", function () {
      window.location.hash = ""
    })
  }

  const alertShow = () => {
    if (showAlert == true) {
      return (
        <div
          id="pricealert"
          className="alert alert-primary alert-dismissible fade show text-center"
          role="alert"
          style={{ position: "fixed", right: "0", bottom: "0", zIndex: "50" }}
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <strong>Want to know more? </strong>

          <PricingAlert closeAlert={scrollToForm} />
        </div>
      )
    } else {
      return <> </>
    }
  }

  return (
    <Layout>
      <SEO
        title=" CBSE, NCERT - Plans and Pricing | Tata Studi"
        description="We have multiple sign up options for you with monthly, 
      half yearly & yearly subscription plan pricing. Choose the best plan 
      to fit your needs & start learning with Tata Studi today!"
        lang="en"
      />

      {alertShow()}

      <div>
        <div className="pageBanner paymentPage bannerText">
          <h1 className="displayNone">Plans and Pricing</h1>
          <h2 className="banner_text_new"> Plans & Pricing </h2>
        </div>
      </div>
      {isMobile ? (
        <div style={{ marginTop: "30px" }}>
          <MobilePayment data={props.data.paymentdata} />
        </div>
      ) : (
        <Payment data={props.data.paymentdata} />
      )}
      <ProductFeatures data={props.data.productfeatures} />
      <PhotoVideoCopy />
      <div id="form">
        <ContactForm />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    productfeatures: allNodeProductFeatures {
      nodes {
        field_product_features_body_text {
          processed
        }
        field_product_features_heading {
          processed
        }
        field_product_features_image {
          alt
        }
        relationships {
          field_product_features_image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    paymentdata: allNodePlansAndPricing {
      nodes {
        field_package_type
        field_per_year_you_pay
        field_per_month_you_pay
        field_refund_type
        field_discount
        field_link_on_button {
          title
          uri
        }
        field_package_details {
          processed
        }
      }
    }
  }
`