import React from "react"
import apiconfig from "../../config/apiconfig"
import sfdcConfig from "../../config/sfdcConfig"
import DatalayerEvents from "../../services/datalayer"
class PricingAlert extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 0,
      inputName: "",
      message: "",
      msgColor: "",
      datalayerEvents: new DatalayerEvents(),
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formValidation = this.formValidation.bind(this)
    this.sendOTP = this.sendOTP.bind(this)
  }

  formValidation = function (contact, otp) {
    let contactRegex = new RegExp(/^[6-9]\d{9}$/)
    let otpRegex = new RegExp(/^\d{4}$/)
    let flag = false

    if (contact && contactRegex.test(contact) && otp && otpRegex.test(otp)) {
      flag = true
    }
    return flag
  }

  handleSubmit = function (event) {
    document.getElementById("price-alert").disabled = true
    event.preventDefault()
    const data = new FormData(event.target)
    let contact = data.get("mobnumber")
    let otp = data.get("otp")

    if (this.formValidation(contact, otp)) {
      // ----------------------------------------------
      let x = Math.floor(Math.random() * (999999 - 100000) + 100000)
      let email = "pricingpopup" + x + "@gmail.com"

      let data = {
        txtname: "NA",
        txtemail: "pricingpopup" + x + "@gmail.com",
        txtcontact: contact,
        btn_submit: "true",
        grade: "NA",
        txtotp: otp,
        time: "NA",
        source: "web",
      }

      var myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

      var urlencoded = new URLSearchParams()

      for (let key in data) {
        urlencoded.append(key, data[key])
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      }

      fetch(apiconfig.FORMSAPI, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result["code"] == 200) {
            this.setState({
              status: 200,
              message: "Submitted!",
              msgColor: "green",
            })

            //USING THE AUTH TOKEN AND PUSHING LEAD TO SFDC
            let authToken = result["token"]

            var SFDCHeaders = new Headers()
            SFDCHeaders.append("Authorization", "Bearer " + authToken)
            SFDCHeaders.append("Content-Type", "application/json")

            let newData = [
              {
                Lastname: "NA",
                Email: email,
                Phone: contact,
                leadsource: "web",
              },
            ]

            var userData = JSON.stringify({
              datalist: newData,
            })

            var SFDCrequestOptions = {
              method: "POST",
              headers: SFDCHeaders,
              body: userData,
              redirect: "follow",
              mode: "cors",
            }

            fetch(sfdcConfig.LEADURL, SFDCrequestOptions)
              .then(response => {
                return { res: response.json(), status: response.status }
              })
              .then(result => {
                if (result.status == 200) {
                  setTimeout(() => {
                    document.getElementById("pricealert").style.display = "none"
                  }, 2000)
                }
              })
          } else {
            document.getElementById("price-alert").disabled = false
            this.setState({
              status: 500,
              message: "Please enter the correct OTP.",
              msgColor: "red",
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      document.getElementById("price-alert").disabled = false
      alert("Please fill all the fields accurately")
    }
  }

  sendOTP = function () {
    let inputMobile = document.getElementById("mobnumber")
    if (!inputMobile.checkValidity()) {
      inputMobile.reportValidity()
      return
    }

    let mob = document.getElementById("mobnumber").value
    let otpHeaders = new Headers()
    otpHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let otpurlencoded = new URLSearchParams()
    otpurlencoded.append("action", "send_otp")
    otpurlencoded.append("contact_no", mob)

    fetch(apiconfig.OTPURL, {
      method: "POST",
      headers: otpHeaders,
      body: otpurlencoded,
      redirect: "follow",
    })
      .then(response => response.json())
      .then(result => {
        document.getElementById("clear").style.visibility = "hidden"
        setTimeout(() => {
          document.getElementById("clear").style.visibility = "visible"
        }, 20000)
      })
      .catch(error => console.log("error", error))
  }

  render() {
    //   getRandomArbitrary = function (100000, 999999) {
    //     return Math.random() * (999999 - 1000000) + 1000000;
    // }

    return (
      <div className="row">
        {/* <div className="col-sm-12"> */}
        <form id="priceAlertForm" onSubmit={this.handleSubmit}>
          {/* <div className="row tataclass-form-row"> */}
          <div className="col-sm-12">
            <input
              type="text"
              id="mobnumber"
              name="mobnumber"
              pattern="^[56789].+[0-9]+"
              title="Number should start with 5, 6,7,8 or 9 
                          and length 10"
              minLength="10"
              maxLength="10"
              className="style_input_tatapage"
              placeholder="Mobile No.*"
              required
            />
            <button
              type="button"
              id="clear"
              className="cf_otp_btn_tatapage"
              onClick={this.sendOTP}
            >
              {" "}
              &nbsp;Get Otp&nbsp;{" "}
            </button>
          </div>
          <div className="col-sm-12">
            <input
              type="text"
              id="otp"
              className="style_input_tatapage"
              name="otp"
              minLength="4"
              maxLength="4"
              pattern="[0-9]+"
              title="Please Enter correct OTP"
              placeholder="OTP Verification"
              required
            />
          </div>
          {/* </div> */}

          <p>
            <span style={{ color: this.state.msgColor, fontSize: "12px" }}>
              {this.state.message}
            </span>
          </p>

          <div>
            <input
              id="price-alert"
              type="submit"
              value="Send"
              className="font-lato"
              style={{
                backgroundColor: "#000",
                color: "white",
                borderRadius: "5px",
                fontWeight: "600",
                fontSize: "16px",
                padding: "1px 10px",
              }}
            />
          </div>
        </form>

        {/* </div> */}
      </div>
    )
  }
}

export default PricingAlert
