import React, { useState } from "react"

import "../css/studilive/pricing.css"

function PricingCards(props) {
  const [index, setIndex] = useState(-1)

  const cardExpand = val => {
    if (index == val) {
      setIndex(-1)
    } else {
      setIndex(val)
    }
  }

  return (
    <div className="payment container-fluid">
      <div className="row mx-1" id="all-plans">
        {props.data.nodes &&
          props.data.nodes.map((item, i) => {
            i = i + 1
            let color
            let bgColor
            if (i == 4 || i % 4 == 0) {
              color = "#FDD038"
              bgColor = "#fee79e"
            } else if (i == 3 || i % 3 == 0) {
              color = "rgb(98, 166, 114)"
              bgColor = "#D4E5D8"
            } else if (i == 2 || i % 2 == 0) {
              color = "rgb(221, 21, 135)"
              bgColor = "#F2C2DD"
            } else {
              color = "rgb(68, 114, 196)"
              bgColor = "#CDD8EC"
            }

            //for splitting plan details in list
            let value = item.field_package_details.processed
            let s_list = value.split("<li>")
            let temp_arr = []
            s_list.forEach(i => {
              let regex = /(<([^>]+)>)/gi
              let result = i.replace(regex, "")
              temp_arr.push(result)
            })

            return (
              <div className="col-lg-4 px-1 px-md-3 mb-3" key={i}>
                <div
                  className="plan-border"
                  style={{ borderRadius: "20px", border: `2px solid ${color}` }}
                >
                  <div
                    className="plan-name font-lato color-white py-4 text-center"
                    style={{ backgroundColor: color }}
                  >
                    {item.field_package_type}
                  </div>
                  <div className="row discount-div">
                    <div className="col-12 mb-4">
                      {item.field_discount && item.field_discount !== "0" ? (
                        <div className="discount-bg">
                          <span className="discount-label font-samsung">
                            You save
                          </span>
                          <span className="discount-value font-samsung">
                            {item.field_discount}
                          </span>
                        </div>
                      ) : (
                        <> </>
                      )}
                    </div>
                  </div>

                  <div
                    className="plan-price color-black text-center"
                    style={{ backgroundColor: bgColor, borderRadius: "30px" }}
                  >
                    Rs. {item.field_per_month_you_pay}
                    <span className="taxes">&nbsp; (incl. of taxes)</span>
                  </div>
                  <div className="text-center mr-3">
                    <div
                      className="plan-details-head"
                      style={{ border: `1px solid ${color}` }}
                    >
                      <span>Plan Details</span>
                      <i
                        className={
                          index == i
                            ? "fa fa-angle-up color-white details-arrow"
                            : "fa fa-angle-down color-white details-arrow"
                        }
                        data-toggle="collapse"
                        href={"#collapse" + i}
                        aria-expanded="false"
                        aria-controls={"collapse" + i}
                        style={{ backgroundColor: color }}
                        onClick={() => cardExpand(i)}
                      />
                    </div>
                  </div>
                  <div className="plan-details"></div>
                  <div
                    className="collapse multi-collapse mx-3 plan-details font-samsung"
                    id={"collapse" + i}
                    data-parent="#all-plans"
                  >
                    <br />
                    {temp_arr.map((val, key) => {
                      if (val != "") {
                        return (
                          <div className="d-flex" key={key}>
                            <i
                              className="fa fa-caret-right trangle-pp"
                              style={{ color: color }}
                              aria-hidden="true"
                            />
                            <span className="know-more-text font-lato">
                              {val}
                            </span>
                          </div>
                        )
                      }
                    })}
                  </div>

                  <div className="text-center mx-auto">
                    <a href="https://learn.tatastudi.com/registration.html">
                      <button className="register-now">Buy Now</button>
                    </a>
                  </div>
                  {item.field_package_type != "Monthly" ? (
                    <div className="text-center mt-n4">
                      <span style={{ color: "red", fontSize: "12px" }}>
                        <i>
                          *EMI facility available for select credit and debit
                          cards
                        </i>
                      </span>
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default PricingCards
